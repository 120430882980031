// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
import { z } from 'zod';
import { sentinelActivityTargetTypeGuard, sentinelActivityActionGuard, sentinelActivityPayloadGuard } from './../foundations/index.js';
import { SentinelActionResult, SentinelDecision } from './custom-types.js';
const createGuard = z.object({
    tenantId: z.string().max(21).optional(),
    id: z.string().min(1).max(21),
    targetType: sentinelActivityTargetTypeGuard,
    targetHash: z.string().min(1).max(64),
    action: sentinelActivityActionGuard,
    actionResult: z.nativeEnum(SentinelActionResult),
    payload: sentinelActivityPayloadGuard,
    decision: z.nativeEnum(SentinelDecision),
    decisionExpiresAt: z.number().optional(),
    createdAt: z.number().optional(),
});
const guard = z.object({
    tenantId: z.string().max(21),
    id: z.string().min(1).max(21),
    targetType: sentinelActivityTargetTypeGuard,
    targetHash: z.string().min(1).max(64),
    action: sentinelActivityActionGuard,
    actionResult: z.nativeEnum(SentinelActionResult),
    payload: sentinelActivityPayloadGuard,
    decision: z.nativeEnum(SentinelDecision),
    decisionExpiresAt: z.number(),
    createdAt: z.number(),
});
export const SentinelActivities = Object.freeze({
    table: 'sentinel_activities',
    tableSingular: 'sentinel_activity',
    fields: {
        tenantId: 'tenant_id',
        id: 'id',
        targetType: 'target_type',
        targetHash: 'target_hash',
        action: 'action',
        actionResult: 'action_result',
        payload: 'payload',
        decision: 'decision',
        decisionExpiresAt: 'decision_expires_at',
        createdAt: 'created_at',
    },
    fieldKeys: [
        'tenantId',
        'id',
        'targetType',
        'targetHash',
        'action',
        'actionResult',
        'payload',
        'decision',
        'decisionExpiresAt',
        'createdAt',
    ],
    createGuard,
    guard,
});
