const action = {
    sign_in: 'Connexion',
    continue: 'Continuer',
    create_account: 'Créer un compte',
    create_account_without_linking: 'Créer un compte sans lier',
    create: 'Créer',
    enter_passcode: 'Entrer le code',
    confirm: 'Confirmer',
    cancel: 'Annuler',
    save_password: 'Enregistrer le mot de passe',
    bind: 'Lier avec {{address}}',
    bind_and_continue: 'Lier et continuer',
    back: 'Aller en arrière',
    nav_back: 'Retour',
    agree: 'Accepter',
    got_it: 'Compris',
    sign_in_with: 'Continuer avec {{name}}',
    forgot_password: 'Mot de passe oublié?',
    switch_to: 'Passer au {{method}}',
    sign_in_via_passcode: 'Se connecter avec le code de vérification',
    sign_in_via_password: 'Se connecter avec le mot de passe',
    change: 'Changer {{method}}',
    link_another_email: 'Lier une autre adresse e-mail',
    link_another_phone: 'Lier un autre numéro de téléphone',
    link_another_email_or_phone: 'Lier une autre adresse e-mail ou un autre numéro de téléphone',
    show_password: 'Afficher le mot de passe',
    copy: 'Copier',
    verify_via_passkey: "Vérifier via la clé d'accès",
    download: 'Télécharger',
};
export default Object.freeze(action);
