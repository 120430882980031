// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY.
export var ApplicationType;
(function (ApplicationType) {
    ApplicationType["Native"] = "Native";
    ApplicationType["SPA"] = "SPA";
    ApplicationType["Traditional"] = "Traditional";
    ApplicationType["MachineToMachine"] = "MachineToMachine";
})(ApplicationType || (ApplicationType = {}));
export var RoleType;
(function (RoleType) {
    RoleType["User"] = "User";
    RoleType["MachineToMachine"] = "MachineToMachine";
})(RoleType || (RoleType = {}));
export var SentinelActionResult;
(function (SentinelActionResult) {
    SentinelActionResult["Success"] = "Success";
    SentinelActionResult["Failed"] = "Failed";
})(SentinelActionResult || (SentinelActionResult = {}));
export var SentinelDecision;
(function (SentinelDecision) {
    SentinelDecision["Undecided"] = "Undecided";
    SentinelDecision["Allowed"] = "Allowed";
    SentinelDecision["Blocked"] = "Blocked";
    SentinelDecision["Challenge"] = "Challenge";
})(SentinelDecision || (SentinelDecision = {}));
export var SignInMode;
(function (SignInMode) {
    SignInMode["SignIn"] = "SignIn";
    SignInMode["Register"] = "Register";
    SignInMode["SignInAndRegister"] = "SignInAndRegister";
})(SignInMode || (SignInMode = {}));
export var UsersPasswordEncryptionMethod;
(function (UsersPasswordEncryptionMethod) {
    UsersPasswordEncryptionMethod["Argon2i"] = "Argon2i";
})(UsersPasswordEncryptionMethod || (UsersPasswordEncryptionMethod = {}));
