const action = {
    sign_in: '登錄',
    continue: '繼續',
    create_account: '註冊',
    create_account_without_linking: '註冊新的帳號',
    create: '註冊',
    enter_passcode: '輸入驗證碼',
    confirm: '確認',
    cancel: '取消',
    save_password: '保存密碼',
    bind: '綁定到 {{address}}',
    bind_and_continue: '綁定並繼續',
    back: '返回',
    nav_back: '返回',
    agree: '同意',
    got_it: '知道了',
    sign_in_with: '通過 {{name}} 繼續',
    forgot_password: '忘記密碼？',
    switch_to: '切換到{{method}}',
    sign_in_via_passcode: '用驗證碼登錄',
    sign_in_via_password: '密碼登錄',
    change: '更改{{method}}',
    link_another_email: '綁定其他郵箱',
    link_another_phone: '綁定其他手機',
    link_another_email_or_phone: '綁定郵箱或手機號',
    show_password: '顯示密碼',
    copy: '複製',
    verify_via_passkey: '透過 Passkey 驗證',
    download: '下載',
};
export default Object.freeze(action);
